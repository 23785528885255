export default [
    {
        path: 'https://www.baidu.com/',
        meta: {
            title: '不懂就百度'
        }
    },
    {
        path: 'http://robot.wx0725.top/ecg/',
        meta: {
            title: '在线机器人'
        }
    },
]
