const Layout = () => import('@/layout/index.vue')

export default {
    path: '/multilevel_menu_example/yule4',
    name: 'yule4',
    component: Layout,
    meta: {
        title: '人工智能',
        icon: 'sidebar-robots'
    },
    children: [{
        path: 'fenci',
        name: 'yule4-1',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/fenci.vue'),
        meta: {
            title: '分词'
        }
    }, {
        path: 'sxfc',
        name: 'yule4-2',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/sxfc.vue'),
        meta: {
            title: '缩写反查'
        }
    }, {
        path: 'yzlogo',
        name: 'yule4-3',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/yzlogo.vue'),
        meta: {
            title: '一字logo'
        }
    }, {
        path: 'splx',
        name: 'yule4-4',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/splx.vue'),
        meta: {
            title: '商品联想'
        }
    }, {
        path: 'yslx',
        name: 'yule4-5',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/yslx.vue'),
        meta: {
            title: '影视联想'
        }
    }, {
        path: 'gmlx',
        name: 'yule4-6',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/gmlx.vue'),
        meta: {
            title: '歌名联想'
        }
    }, {
        path: 'dbsx',
        name: 'yule4-7',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/dbsx.vue'),
        meta: {
            title: '对白手写图片'
        }
    }, {
        path: 'dywsy',
        name: 'yule4-8',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/dywsy.vue'),
        meta: {
            title: '抖音无水印'
        }
    }, {
        path: 'smaplerobot',
        name: 'yule4-9',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/smaplerobot.vue'),
        meta: {
            title: '机器人A版'
        }
    }, {
        path: 'sjtx',
        name: 'yule4-10',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/sjtx.vue'),
        meta: {
            title: '随机头像'
        }
    }, {
        path: 'erweima',
        name: 'yule4-11',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/erweima.vue'),
        meta: {
            title: '二维码生成'
        }
    }, {
        path: 'sjbz',
        name: 'yule4-12',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/sjbz.vue'),
        meta: {
            title: '动漫壁纸'
        }
    }, {
        path: 'xbpd',
        name: 'yule4-13',
        component: () => import(
            '@/views/multilevel_menu_example/yule4/xbpd.vue'),
        meta: {
            title: '性别判断'
        }
    }]
}
