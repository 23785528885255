const Layout = () => import('@/layout/index.vue')

export default {
    path: '/multilevel_menu_example/yule3',
    name: 'yule3',
    component: Layout,
    meta: {
        title: '游戏娱乐',
        icon: 'sidebar-games'
    },
    children: [{
        path: 'nywc',
        name: 'yule3-1',
        component: () => import(
            '@/views/multilevel_menu_example/yule3/nywc.vue'),
        meta: {
            title: '你演我猜'
        }
    }, {
        path: 'shisanchai',
        name: 'yule3-2',
        component: () => import(
            '@/views/multilevel_menu_example/yule3/shisanchai.vue'),
        meta: {
            title: '十三杈'
        }
    }, {
        path: 'diange',
        name: 'yule3-3',
        component: () => import(
            '@/views/multilevel_menu_example/yule3/diange.vue'),
        meta: {
            title: '点歌全套'
        }
    }, {
        path: 'diangePlus',
        name: 'yule3-4',
        component: () => import(
            '@/views/multilevel_menu_example/yule3/diangePlus.vue'),
        meta: {
            title: '点歌加强版'
        }
    }]
}
