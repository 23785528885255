const Layout = () => import('@/layout/index.vue')

export default {
    path: '/multilevel_menu_example/level2/level3',
    name: 'yule1',
    component: Layout,
    redirect: '/multilevel_menu_example/level2/level3/page1',
    meta: {
        title: '资讯',
        icon: 'sidebar-news'
    },
    children: [{
        path: 'page1',
        name: 'yule1-1',
        component: () => import(
            '@/views/multilevel_menu_example/level2/level3/page1.vue'),
        meta: {
            title: '全网热搜'
        }
    }]
}
