const Layout = () => import('@/layout/index.vue')

export default {
    path: '/multilevel_menu_example/HuDong',
    name: 'hudong',
    component: Layout,
    meta: {
        title: '有互动',
        icon: 'sidebar-hudong'
    },
    children: [{
        path: 'ajax',
        name: 'hudong-2',
        component: () => import(
            '@/views/multilevel_menu_example/HuDong/ajax.vue'),
        meta: {
            title: '请求实例'
        }
    }, {
        path: 'moreApi',
        name: 'hudong-3',
        component: () => import(
            '@/views/multilevel_menu_example/HuDong/moreApi.vue'),
        meta: {
            title: '更多API分享'
        }
    }]
}
