const Layout = () => import('@/layout/index.vue')

export default {
    path: '/multilevel_menu_example/yule5',
    component: Layout,
    redirect: '/multilevel_menu_example/page',
    name: 'yule',
    meta: {
        title: '服务器',
        icon: 'sidebar-linux'
    },
    children: [{
        path: 'ping',
        name: 'yule5-1',
        component: () => import(
            '@/views/multilevel_menu_example/yule5/ping.vue'),
        meta: {
            title: 'Ping'
        }
    },{
        path: 'ip',
        name: 'yule5-2',
        component: () => import(
            '@/views/multilevel_menu_example/yule5/ip.vue'),
        meta: {
            title: 'IP定位-1'
        }
    }]
}
