let globalSettings = {
    app: {
        // Element 组件默认尺寸，支持：large、default、small
        elementSize: 'default',
        /**
         * 是否开启权限功能，权限功能提供以下鉴权支持：
         * 1、路由鉴权
         * 2、鉴权组件：<Auth></Auth>、<AuthAll></AuthAll>
         * 3、鉴权指令：v-auth、v-auth-all
         * 4、鉴权函数：this.$auth()、this.$authAll()
         */
        enablePermission: false,
        // 是否开启后端返回路由数据
        enableBackendReturnRoute: false,
        // 是否开启载入进度条
        enableProgress: true,
        // 是否开启动态标题
        enableDynamicTitle: false
    },
    // 控制台
    dashboard: {
        // 是否开启
        enable: true,
        // 控制台名称
        title: '首页'
    },
    // 布局
    layout: {
        // 是否开启移动端适配，开启后当页面宽度小于 992px 时自动切换为移动端展示
        enableMobileAdaptation: true
    },
    // 导航栏
    menu: {
        /**
         * 导航栏模式
         * side 侧边栏模式（含主导航）
         * head 顶部模式
         * single 侧边栏模式（无主导航）
         */
        menuMode: 'head',
        // 切换主导航同时跳转页面
        switchMainMenuAndPageJump: false,
        // 次导航只保持一个子项的展开
        subMenuUniqueOpened: true,
        // 次导航是否收起
        subMenuCollapse: false
    },
    // 顶栏
    "topbar": {
        "fixed": true,
        "enableSidebarCollapse": true,
        "enableBreadcrumb": true,
        "enableNavSearch": true,
        "enableFullscreen": true,
        "enablePageReload": false,
        "enableAppSetting": true
    },
    // 底部版权
    "copyright": {
        "enable": true,
        "dates": "2021-2022",
        "company": "轩API",
        "website": "http://wx0725.top/"
    }
}


import settingsCustom from './settings.custom.json'
Object.assign(globalSettings, settingsCustom)

// 演示&开发环境开启全部功能（这部分代码可删除，仅方便作者打包演示环境）
if (import.meta.env.VITE_APP_MODE === 'example' || import.meta.env.MODE === 'development') {
    globalSettings.app.enablePermission = true
    globalSettings.app.enableDynamicTitle = true
    globalSettings.topbar.enableSidebarCollapse = true
    globalSettings.topbar.enableFullscreen = true
    globalSettings.topbar.enablePageReload = true
    globalSettings.topbar.enableAppSetting = true
}

export default globalSettings
