const Layout = () => import('@/layout/index.vue')

export default {
    path: '/multilevel_menu_example/yule2',
    name: 'yule2',
    component: Layout,
    meta: {
        title: '生活工具',
        icon: 'sidebar-tools'
    },
    children: [{
        path: 'sjwz',
        name: 'yule2-1',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjwz.vue'),
        meta: {
            title: '随即文章',
        }
    }, {
        path: 'xiaohua',
        name: 'yule2-2',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/xiaohua.vue'),
        meta: {
            title: '随机笑话'
        }
    }, {
        path: 'sjxs',
        name: 'yule2-3',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjxs.vue'),
        meta: {
            title: '随机相声'
        }
    }, {
        path: 'ktff',
        name: 'yule2-4',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/ktff.vue'),
        meta: {
            title: '口吐芬芳'
        }
    }, {
        path: 'ktcy',
        name: 'yule2-5',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/ktcy.vue'),
        meta: {
            title: '搜成语'
        }
    }, {
        path: 'sjcy',
        name: 'yule2-6',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjcy.vue'),
        meta: {
            title: '随机成语'
        }
    }, {
        path: 'cpinyin',
        name: 'yule2-8',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/cpinyin.vue'),
        meta: {
            title: '查拼音'
        }
    }, {
        path: 'sjtc',
        name: 'yule2-9',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjtc.vue'),
        meta: {
            title: '随机台词'
        }
    }, {
        path: 'swmj',
        name: 'yule2-10',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/swmj.vue'),
        meta: {
            title: '散文美句'
        }
    }, {
        path: 'sjqs',
        name: 'yule2-11',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjqs.vue'),
        meta: {
            title: '随机情书'
        }
    }, {
        path: 'sjnc',
        name: 'yule2-12',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjnc.vue'),
        meta: {
            title: '随机昵称'
        }
    }, {
        path: 'ybqh',
        name: 'yule2-13',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/ybqh.vue'),
        meta: {
            title: '邮编区号查询'
        }
    }, {
        path: 'dxxx',
        name: 'yule2-14',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/dxxx.vue'),
        meta: {
            title: '大学信息查询'
        }
    }, {
        path: 'tianqi',
        name: 'yule2-15',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/tianqi.vue'),
        meta: {
            title: '天气查询'
        }
    }, {
        path: 'sjyy',
        name: 'yule2-16',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjyy.vue'),
        meta: {
            title: '随机一言'
        }
    }, {
        path: 'wyyrp',
        name: 'yule2-17',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/wyyrp.vue'),
        meta: {
            title: '网易云热评'
        }
    }, {
        path: 'skl',
        name: 'yule2-18',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/skl.vue'),
        meta: {
            title: '顺口溜'
        }
    }, {
        path: 'en46',
        name: 'yule2-19',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/en46.vue'),
        meta: {
            title: '四六级查询'
        }
    }, {
        path: 'xhy',
        name: 'yule2-20',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/xhy.vue'),
        meta: {
            title: '歇后语'
        }
    }, {
        path: 'sjyl',
        name: 'yule2-21',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/sjyl.vue'),
        meta: {
            title: '随机语录'
        }
    }, {
        path: 'kzls',
        name: 'yule2-22',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/kzls.vue'),
        meta: {
            title: '抗战历史'
        }
    }, {
        path: 'lunyu',
        name: 'yule2-23',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/lunyu.vue'),
        meta: {
            title: '论语十二章'
        }
    }, {
        path: 'poems',
        name: 'yule2-24',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/poems.vue'),
        meta: {
            title: '随机诗词'
        }
    }, {
        path: 'poetry',
        name: 'yule2-25',
        component: () => import(
            '@/views/multilevel_menu_example/yule2/poetry.vue'),
        meta: {
            title: '随机诗歌'
        }
    }]
}
